import {Injectable} from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DynamicBuilderService {
  constructor(){}
  private acToolbarExec = new BehaviorSubject<string>('none');
  castAcToolbarExec = this.acToolbarExec.asObservable();

  setActionToolbar(action) {
    this.acToolbarExec.next(action);
  }
}
