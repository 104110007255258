import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OptionCatalogGenericInterface } from '../../../../interfaces/option-catalog-generic.interface';
import {ApiCoreService} from '../../../../services/api-core.service';

@Component({
    selector: 'app-atom-input-select-td-filter',
    templateUrl: './input-select-td-filter.component.html',
    styleUrls: ['./input-select-td-filter.component.sass']
})
export class InputSelectTdFilterComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;

    options: OptionCatalogGenericInterface[] = [];

    constructor(
      private api: ApiCoreService,
    ) {
    }

    ngOnInit(): void {
      const body = {
        p_field_id: this.field.code, 
      }
      this.api.getOpSelect(body).subscribe( data => {
        if (data.data !== null) {
          Object.keys(data.data).forEach( item => {
            const op: OptionCatalogGenericInterface  = {
              recordId: item,
              description: data.data[item],
            };
            this.options.push(op);
          });
        }
      });
    }

}
