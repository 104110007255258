import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import IMask from "imask";

@Component({
    selector: 'app-atom-input-integer',
    templateUrl: './input-integer.component.html',
    // styleUrls: ['./input-text.component.sass']
})
export class InputIntegerComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;
  public number= 9999999999;

    get isValid() {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    /*maskOptions = {
      mask: IMask.MaskedNumber,
      min: 1,
      max: 9999999999
    };*/

    constructor() {
    }

    ngOnInit(): void {
    }

}
