import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {DataSearchComponent} from '../../../../../shared/components/data-search/data-search.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-atom-input-search',
    templateUrl: './input-search.component.html',
    // styleUrls: ['./input-text.component.sass']
})
export class InputSearchComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;

    get isValid(): boolean {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty(): boolean {
        return this.form.controls[this.field.name].dirty;
    }

    constructor(
      public dialog: MatDialog
    ) { }

    ngOnInit(): void {
    }

  openDialog(): void {
    this.dialog.open(DataSearchComponent, {
      width: "80%",
      height: "auto",
      disableClose: true,
      data: {
        fieldIdSelected: this.field.code,
        fieldNameSelected: this.field.name
      }
    })
    // .afterClosed().subscribe((result) => {
      // if(result?.action === "onSave") {
          // this.dialog.close({ action: "onSelected", payload: { bpartnerId: result?.payload?.c_bpartner_id, name: result?.payload?.name } });
      // }
    // });
  }

}
