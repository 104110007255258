import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Cmyk, ColorPickerService} from 'ngx-color-picker';

@Component({
  selector: 'app-atom-input-color',
  templateUrl: './input-color.component.html',
  // styleUrls: ['./input-color.component.sass']
})
export class InputColorComponent implements OnInit {

  @Input() field: any = {};
  @Input() form: FormGroup;

  public color: any;


  get isValid(): boolean {
    return this.form.controls[this.field.name].valid;
  }

  get isDirty(): boolean {
    return this.form.controls[this.field.name].dirty;
  }

  constructor(
    private cpService: ColorPickerService
  ) {
  }

  ngOnInit(): void {
  }

}
