import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-atom-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.sass']
})
export class InputCheckboxComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;

    isValid() {
        return this.form.controls[this.field.name].valid;
    }

    isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
