import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { FieldInterface } from 'app/core/interfaces/windows-fields-interface/field.interface';
import { ApiCoreService } from 'app/core/services/api-core.service';
import * as moment from "moment";
import { TabInterface } from 'app/core/interfaces/windows-fields-interface/tab.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-data.search',
  templateUrl: './data-search.component.html',
  // styleUrls: ['./dialogform.component.sass']
})
export class DataSearchComponent implements OnInit, OnChanges {

  @Input() field: any = {};
  public fieldsSearch: FieldInterface[];
  public searchForm: FormGroup;
  @Input() fieldIdSelected: number;
  @Input() fieldNameSelected: string;

  columns = [];
  data = [];
  @Input() tab: TabInterface;
  @Input() recordCurrentId;
  @Output() valueSelected = new EventEmitter<any>();
  recordIdSelected = 0;
  totalrecords = 0;
  numberOfElements = 0;
  totalpages = 0;
  firtsPage = true;
  lastPage = false;
  public pageSelected: number;
  public searchData: any;

  public tabForm: TabInterface;
  showAdd: boolean;
  showData = true;

  constructor (
    private apiService: ApiCoreService,
    public dialog: MatDialogRef<DataSearchComponent>,
    public dialogRef: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public info
  ) {
      this.searchForm = this.formBuilder.group({});
      this.searchForm = new FormGroup({});
    }

  public ngOnInit(): void {
    this.pageSelected = 0;
    if (this.info?.fieldIdSelected) {
      const body = {
        codeId: this.info?.fieldIdSelected,
        type: 'F'
      }
      this.apiService.getSearchField(body).subscribe(response => {
        this.fieldsSearch = response.data.fields;
        this.setForm();
      });
      this.getGridByField(this.pageSelected);
    }
  }

  setForm(): void {
    const fieldsCtrls = {};
    this.fieldsSearch.forEach(field => {
      if(field.required) {
        fieldsCtrls[field.columnname.toLowerCase()] = new FormControl( '', Validators.required);
      } else {
      fieldsCtrls[field.columnname.toLowerCase()] = new FormControl( '');
      }
    });
    this.searchForm = new FormGroup(fieldsCtrls);
  }

  getGridByField(offsetPage: number) {
    this.columns = [];
    this.data = [];
    let body = {};

    if (this.info?.fieldIdSelected !== null && this.info?.fieldIdSelected !== undefined) {
        body = {
          fieldId: this.info?.fieldIdSelected,
          offset: offsetPage * 10,
          limit: 10,
          searchData: null,
        };
    }

    this.apiService.getGridByField(body).subscribe(response => {
      this.data = [];
      this.columns = [];
      let headerArray = [];
      headerArray = response.headers.sort((n1, n2) => {
        if (n1.seqno > n2.seqno) {
          return 1;
        }
        if (n1.seqno < n2.seqno) {
          return -1;
        }
        return 0;
      });
      headerArray.forEach(item => {
        const body = {};
        Object.keys(item).forEach((key) => {
          if (key === 'seqno') {
            body['order'] = item[key];
          } else if (key === 'datatype') {
            body['dataType'] = item[key];
          } else {
            body['field'] = key;
            body['value'] = item[key];
          }
        });
        this.columns.push(body);
      });
      response.contents.forEach(data => {
        const contentLine = {};
        const dataArray = [];
        contentLine['recordId'] = data.record_id;
        this.columns.forEach((column) => {
          Object.keys(data.data_record).forEach( key => {
            if ( key === column.field ){
              let itemType = this.columns.find(i => i.field === key);
              const body = {
                column: key,
                value: data.data_record[key],
                dataType: itemType.dataType
              };
              dataArray.push(body);
            }
          });
        });
        contentLine['data'] = dataArray;
        this.data.push(contentLine);
      });
      this.numberOfElements = this.data.length;
      this.totalrecords = response.records;
      this.totalpages = Math.ceil(this.totalrecords / 10);
    });
  }

  public addRecord(): void {
    this.showAdd = true;
    this.showData = false
  }

  ngOnChanges() {
    this.recordIdSelected = 0;
    this.pageSelected = 0;
  }

  public onPageSelected(page: number) {
    this.pageSelected = page;
    this.getGridByField(this.pageSelected);
    if (this.pageSelected === 0) {
      this.firtsPage = true;
      this.lastPage = false;
    } else if ( (this.pageSelected + 1) === this.totalpages) {
      this.firtsPage = false;
      this.lastPage = true;
    } else {
      this.firtsPage = false;
      this.lastPage = false;
    }
  }

  recordSelected(value) {
    this.recordIdSelected = value.recordId;
    const body = {
      recordId: value.recordId,
    };
    this.valueSelected.emit(body);
  }

  formatDateTime(date) {
    return moment(date).format('MM/DD/YYYY, h:mm:ss a')
  }

  formatDate(date) {
    return moment(date).format('MM/DD/YYYY')
  }

  closeDialog(): void {
    this.dialog.close({ action: "onClose", payload: null });
  }

  back() {
    this.showAdd = false
    this.showData = true
  }

}
