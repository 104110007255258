<div [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>{{field.label}}</mat-label>
    <div class="d-flex justify-content-between">
      <input matTooltip="{{ field.short_description }}" matInput
             [formControlName]="field.columnname.toLowerCase()"
             [required]="field.required" autocomplete="off"
             [value]="color"
             [cpOKButton]="true"
             [cpCancelButton]="true"
             [(colorPicker)]="color"
             [cpPosition]="'top-right'"/>
      <p [style]="'background: '+color+'; color: '+color">color</p>
    </div>
    <!--<mat-error *ngIf="form.get(field.name).hasError('required')">
        &lt;!&ndash;{{ field.errorMessage.value }}&ndash;&gt;
    </mat-error>-->
  </mat-form-field>
</div>
