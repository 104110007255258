import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PaginationComponent} from './components/pagination/pagination.component';
import {DataSearchComponent} from './components/data-search/data-search.component';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import { DynamicBuilderService } from 'app/core/services/dynamic-builder.service';
import { DynamicFormBuilderModule } from 'app/core/components/dynamic-form-builder/dynamic-form-builder.module';
import { MaintenanceModule } from 'app/modules/screen-dynamic-builder/maintenance/maintenance.module';
import { AddFormComponent } from './components/add-form/add-form.component';
import { InputSearchComponent } from 'app/core/components/dynamic-form-builder/atoms/input-search/input-search.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        PaginationComponent,
        DataSearchComponent,
        AddFormComponent, 
    ],
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxSpinnerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        DynamicFormBuilderModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxSpinnerModule,
        PaginationComponent
    ]
})
export class SharedModule
{
}
