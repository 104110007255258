import { Injectable, OnInit } from '@angular/core';
import CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService implements OnInit {
  
    secretKey = environment.secretKey;

    constructor( ) { }
    
    ngOnInit(): void { }

    encryptData(key: string, dataSession) {
        try {
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataSession), this.secretKey).toString();
            localStorage.setItem(key, encryptedData);
            // console.log('encryptData SI', key, encryptedData);
        } catch (error) {
            // console.error('encryptData NO', error);
        }
    }

    decryptData(key: string) {
        const encryptedData = localStorage.getItem(key);

        if (!encryptedData) return null;
        try {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.secretKey).toString(CryptoJS.enc.Utf8);

            if (decryptedData) {
                try {
                    return JSON.parse(decryptedData);
                } catch (parseError) {
                    console.error('DecryptedData parseError', parseError);
                    return null;
                }
            } else {
                console.warn('DecryptedData no valid or empty');
                return null;
            }
        } catch (e) {
            console.error('DecryptedData Error', e);
            return null;
        }
    }

  // Eliminar un ítem específico de localStorage
  // removeItem(key: string): void {
  //   localStorage.removeItem(key);
  // }

}
