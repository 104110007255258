import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Amplify} from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface IUser {
    email: string;
    password: string;
    showPassword: boolean;
    code: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class CognitoService {

    private authenticationSubject: BehaviorSubject<any>;

    constructor() {
        Amplify.configure({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Auth: environment.cognito,
            authenticationFlowType: 'USER_PASSWORD_AUTH'
        });

        this.authenticationSubject = new BehaviorSubject<boolean>(false);
    }

    public signUp(): Promise<any> {
        return Auth.signUp({
            username: 'jrra.3108@gmail.com',
            password: 'Jrytmc$3108',
        });
    }

    public confirmSignUp(user: IUser): Promise<any> {
        return Auth.confirmSignUp(user.email, user.code);
    }

    public signIn(email, password): Promise<any> {
        return Auth.signIn(email, password)
            .then((data) => {
                this.authenticationSubject.next(true);
                return data;
            });
    }

    public changePassTemp(data, password): Promise<any> {
        return Auth.completeNewPassword(data, password)
            .then((dataResp) => {
                this.authenticationSubject.next(true);
                return dataResp;
            });
    }

    public signOut(): Promise<any> {
        return Auth.signOut()
            .then(() => {
                this.authenticationSubject.next(false);
            });
    }

    public isAuthenticated(): Promise<boolean> {
        if (this.authenticationSubject.value) {
            return Promise.resolve(true);
        } else {
            return this.getUser()
                .then((user: any) => {
                    if (user) {
                        return true;
                    } else {
                        return false;
                    }
                }).catch(() => {
                    return false;
                });
        }
    }

    public getUser(): Promise<any> {
        return Auth.currentUserInfo();
    }

    public getSession(): Promise<any> {
        return Auth.currentSession();
    }

    getAccessToken(): Observable<string> {
        return from(Auth.currentSession()).pipe(
            switchMap(session => from(session.getAccessToken().getJwtToken())
            ));
    };
    public updateUser(user: IUser): Promise<any> {
        return Auth.currentUserPoolUser()
            .then((cognitoUser: any) => {
                return Auth.updateUserAttributes(cognitoUser, user);
            });
    }

}
