<div class="pagination flex justify-center">
  <ul class="d-flex justify-content-center mt-5">
    
    <li *ngIf="firstPage" class="disabled">
      <a class="page-link">First</a>
    </li>

    <li *ngIf="firstPage" class="disabled">
      <a class="page-link" style="background-color: white;"> 
        <mat-icon>keyboard_arrow_left</mat-icon> 
      </a>
    </li>

    <li *ngIf="!firstPage" style="background-color: white;">
      <a (click)="notifycationPageSelected(0)" class="page-link"> First </a>
    </li>


    <li *ngIf="!firstPage" class="previous">
      <a (click)="notifycationPageSelected(currentPage-1)" class="page-link">
         <mat-icon>keyboard_arrow_left</mat-icon> 
      </a>
    </li>

    <li *ngFor="let page of this.listShowPages">
      <a class="page-link" [ngClass]="{ 'active': page === currentPage } " (click)="notifycationPageSelected(page)">
        {{page + 1}}
      </a>
    </li>

    <li *ngIf="lastPage" class="disabled">
      <a class="page-link" style="background-color: white;"> 
        <mat-icon>keyboard_arrow_right</mat-icon> 
      </a>
    </li>

    <li *ngIf="!lastPage">
      <a (click)="notifycationPageSelected(currentPage+1)" class="page-link">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </a>
    </li>

    <li *ngIf="lastPage" class="disabled">
      <a class="page-link" style="background-color: white;"> Last </a>
    </li>

    <li *ngIf="!lastPage">
      <a (click)="this.notifycationPageSelected(this.totalPage - 1)" class="page-link"> Last </a>
    </li>

  </ul>
</div>
<div class="d-flex justify-content-center">
  <div role="status" aria-live="polite" style="color: #1E293B;">
    <strong> 
      Showing {{(currentPage * recordByPage) + 1}} to {{( currentPage * recordByPage) + numberOfElements}} of {{ totalElements }} records 
    </strong>
  </div>
</div>
