import { NgModule } from '@angular/core';
import { InputCheckboxComponent } from './atoms/input-checkbox/input-checkbox.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { InputSelectboxComponent } from './atoms/input-selectbox/input-selectbox.component';
import { TextPComponent } from './atoms/text-p/text-p.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InputTextComponent } from './atoms/input-text/input-text.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputDateComponent } from './atoms/input-date/input-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InputTimeComponent } from './atoms/input-time/input-time.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { InputTimestampComponent } from './atoms/input-timestamp/input-timestamp.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputImageComponent } from './atoms/input-image/input-image.component';
import { InputSelectTdComponent } from './atoms/input-select-td/input-select-td.component';
import { InputSelectTdFilterComponent } from './atoms/input-select-td-filter/input-select-td-filter.component';
import { InputAccountComponent } from './atoms/input-account/input-account.component';
import { InputAmountComponent } from './atoms/input-amount/input-amount.component';
import { InputColorComponent } from './atoms/input-color/input-color.component';
import { InputIdComponent } from './atoms/input-id/input-id.component';
import { InputIntegerComponent } from './atoms/input-integer/input-integer.component';
import { InputSearchComponent } from './atoms/input-search/input-search.component';
import { InputTextAreaComponent } from './atoms/input-text-area/input-text-area.component';
import { InputFilenameComponent } from './atoms/input.filename/input-filename.component';
import { InputNumberComponent } from './atoms/input-number/input-number.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxMaskModule } from 'ngx-mask';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    imports: [
        MatDatepickerModule,
        NgbDatepickerModule,
        MatNativeDateModule,
        OwlDateTimeModule,
        ReactiveFormsModule,
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        ColorPickerModule,
        NgxCurrencyModule,
        NgxMaskModule,
        MaterialFileInputModule,
    ],
    declarations: [
        InputCheckboxComponent,
        FieldBuilderComponent,
        InputSelectboxComponent,
        TextPComponent,
        InputTextComponent,
        InputDateComponent,
        InputNumberComponent,
        InputTimeComponent,
        InputTimestampComponent,
        InputImageComponent,
        InputSelectTdComponent,
        InputSelectTdFilterComponent,
        InputAccountComponent,
        InputAmountComponent,
        InputColorComponent,
        InputIdComponent,
        InputIntegerComponent,
        InputSearchComponent,
        InputTextAreaComponent,
        InputFilenameComponent
    ],
    exports: [
        FieldBuilderComponent,
        InputCheckboxComponent,
        InputSelectboxComponent,
        TextPComponent,
        InputTextComponent,
        InputDateComponent,
        InputNumberComponent,
        InputTimeComponent,
        InputTimestampComponent,
        InputImageComponent,
        InputSelectTdComponent,
        InputAccountComponent,
        InputColorComponent,
        InputAmountComponent,
        InputIdComponent,
        InputFilenameComponent,
        InputIntegerComponent,
        InputTextAreaComponent,
        InputSearchComponent,
        InputTextComponent,
    ],
})
export class DynamicFormBuilderModule {}
