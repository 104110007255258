<div [formGroup]="form">
    <mat-form-field class="w-full">
      <mat-label>{{field.label}}</mat-label>
      <ngx-mat-file-input [formControlName]="field.columnname.toLowerCase()" [required]="field.required" matTooltip="{{ field.short_description }}">
      </ngx-mat-file-input>
      <mat-icon matSuffix>cloud_upload</mat-icon>

        <!--<mat-label>{{field.label}}</mat-label>
        <input type="file" matTooltip="{{ field.short_description }}" matInput [formControlName]="field.columnname.toLowerCase()" [required]="field.required" autocomplete="off" />-->
        <!--<mat-error *ngIf="form.get(field.name).hasError('required')">
            &lt;!&ndash;{{ field.errorMessage.value }}&ndash;&gt;
        </mat-error>-->
    </mat-form-field>
</div>
