export const environment = {
    secretKey: 'O5PEgoB2YE4bZXcO9VDYyaX9a7pyliKV',
    production: true,
    userPoolId: 'us-west-1_x9QVrFmBY',
    cognitoClientId: '2ioki8pad07oc8sk8nb8nmfkj2',
    clientId: 1000001,
    cognito: {
        userPoolId: 'us-west-1_x9QVrFmBY',
        userPoolWebClientId: '2ioki8pad07oc8sk8nb8nmfkj2',
    },
    appId: 'X9RN4S',
    applicationName: 'Administration Portal',
    imgBackground: 'assets/images/auth/bg-main.png',
    apiAdminUrl: 'https://dev-wservices-admin.jcfinancial.us/v1/bankingsoft/admin'
};
