import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CurrencyMaskConfig, CurrencyMaskInputMode} from "ngx-currency";

// import IMask from "imask";

@Component({
    selector: 'app-atom-input-number',
    templateUrl: './input-number.component.html',
    // styleUrls: ['./input-text.component.sass']
})
export class InputNumberComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;
    currencyMaskConfig: CurrencyMaskConfig = {
      align: "right",
      allowNegative: false,
      allowZero: false,
      decimal: ".",
      precision: 2,
      prefix: "$ ",
      suffix: "",
      thousands: ",",
      nullable: true,
      inputMode: CurrencyMaskInputMode.FINANCIAL
    };

    get isValid() {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    /*maskOptions = {
      mask: IMask.MaskedNumber,
      min: 1,
      max: 9999999999
    };*/

    constructor() {
    }

    ngOnInit(): void {
    }

}
