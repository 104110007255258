<div [formGroup]="form">
    
    <mat-form-field class="w-75" appearance="outline">
        <mat-label> TableList - {{ field.label }} </mat-label>
        <mat-select [formControlName]="field.columnname.toLowerCase()" >
            <mat-option *ngFor="let opt of options" [value]="opt.recordId">
                {{ opt.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
</div>
