import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TabInterface } from 'app/core/interfaces/windows-fields-interface/tab.interface';
import { ApiCoreService } from 'app/core/services/api-core.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'add-form',
  templateUrl: './add-form.component.html',
})
export class AddFormComponent {

  public addForm: FormGroup;
  @Input() tab: TabInterface;
  @Output() backEmitter= new EventEmitter<boolean>();
  @Input() fieldIdSelected: number;

  constructor ( private apiService: ApiCoreService,
                public dialog: MatDialogRef<AddFormComponent>,
                public dialogRef: MatDialog
              ) {
      this.addForm = new FormGroup({});
    }

    ngOnInit(): void {
        this.apiService.getQuickTab(this.fieldIdSelected).subscribe(response => {
            this.tab =  response.data
            this.setForm();
        });
    }


    setForm(): void {
      const fieldsCtrls = {};
      this.tab.fieldgroups.forEach((fieldGroup) => {
          if (fieldGroup.fields) {
              for (const f of fieldGroup.fields) {
                  if (f.required) {
                      fieldsCtrls[f.columnname.toLowerCase()] = new FormControl('', Validators.required);
                  } else {
                      fieldsCtrls[f.columnname.toLowerCase()] = new FormControl('');
                  }
                  this.addForm = new FormGroup(fieldsCtrls);
              }
          }
      });
    }

  public saveRecord(): void {
    // this.backEmitter.emit(true);
    if (this.addForm.valid) {
        const dataInsert = {};
        Object.keys(this.addForm.controls).forEach(key => {
            if (this.addForm.controls[key].value === true) {
                dataInsert[key] = 'Y';
            } else if (this.addForm.controls[key].value === false) {
                dataInsert[key] = 'N';
            } else if (this.addForm.controls[key].value === '') {
                dataInsert[key] = 'null';
            } else if (this.addForm.controls[key].value === null) {
                dataInsert[key] = 'null';
            } else {
                dataInsert[key] = this.addForm.controls[key].value;
            }
        });

        const body = {
            data: {
                tableName: this.tab.tablename,
                data: dataInsert
            }
        };
        this.apiService.insertRecord(body).subscribe((response) => {
            if (response.code === 200) {
                Swal.fire('Created', 'Record created', 'success');
                this.backEmitter.emit(true);
            } else {
                Swal.fire('Warning', 'Something Went Wrong', 'error');
            }
        });
    } else {
        Swal.fire('Warning', 'Complete Required Fields', 'warning');
    }
}

closeModal(): void {
    this.dialog.close({ action: "onClose", payload: null });
}


}
