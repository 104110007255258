import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-component-field-builder',
    template: `
  <div class="form-group" [formGroup]="form">
    <div [ngSwitch]="field.atom_type">
        <app-atom-input-search *ngSwitchCase="'search'" [field]="field" [form]="form"></app-atom-input-search>
        <app-atom-input-account *ngSwitchCase="'account'" [field]="field" [form]="form"></app-atom-input-account>
        <app-atom-input-amount *ngSwitchCase="'amount'" [field]="field" [form]="form"></app-atom-input-amount>
        <app-atom-input-color *ngSwitchCase="'color'" [field]="field" [form]="form"></app-atom-input-color>
        <app-atom-input-date *ngSwitchCase="'date'" [field]="field" [form]="form"></app-atom-input-date>
        <app-atom-input-timestamp *ngSwitchCase="'timestamp'" [field]="field" [form]="form"></app-atom-input-timestamp>
        <app-atom-input-filename *ngSwitchCase="'filename'" [field]="field" [form]="form"></app-atom-input-filename>
        <app-atom-input-id *ngSwitchCase="'id'" [field]="field" [form]="form"></app-atom-input-id>
        <app-atom-input-integer *ngSwitchCase="'integer'" [field]="field" [form]="form"></app-atom-input-integer>
        <app-atom-input-select-td *ngSwitchCase="'tablelist'" [field]="field" [form]="form"></app-atom-input-select-td>
        <app-atom-input-text-area *ngSwitchCase="'textarea'" [field]="field" [form]="form"></app-atom-input-text-area>
        <app-atom-input-number *ngSwitchCase="'number'" [field]="field" [form]="form"></app-atom-input-number>
        <app-atom-input-selectbox *ngSwitchCase="'selectbox'" [field]="field" [form]="form"></app-atom-input-selectbox>
        <app-atom-input-select-td-filter *ngSwitchCase="'tablefilter'" [field]="field" [form]="form"></app-atom-input-select-td-filter>
        <app-atom-input-text *ngSwitchCase="'string'" [field]="field" [form]="form"></app-atom-input-text>
        <app-atom-input-text *ngSwitchCase="'text'" [field]="field" [form]="form"></app-atom-input-text>
        <app-atom-input-time *ngSwitchCase="'time'" [field]="field" [form]="form"></app-atom-input-time>
        <app-atom-input-checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></app-atom-input-checkbox>
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} is required</div>
      <!--<p>{{ field.atom_type }}</p>-->
    </div>
  </div>
  `
})
export class FieldBuilderComponent implements OnInit {
    @Input() field: any;
    @Input() form: FormGroup;
    @Output() formGroupEventEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    isValid(): boolean {
        return this.form.controls[this.field.name].valid;
    }

    isDirty(): boolean {
        return this.form.controls[this.field.name].dirty;
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe((t) => {
            this.formGroupEventEmitter.emit(t);
        });
    }
}
