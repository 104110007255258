import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {from, Observable, switchMap, tap} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';;
import {Router} from '@angular/router';
import {CognitoService} from '../services/cognito.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private router: Router,
        private cognitoService: CognitoService
        )
    {
    }

    /**
     * Intercept
     *
     * @param request
     * @param next
     */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const url = '/v1/bankingsoft/admin/';
        const urlAuth = '/v1/bankingsoft/admin/auth';
        const urlCore = '/v1/bankingsoft/core/';
        // add authorization header with jwt token if available

        return from(this.cognitoService.getSession()).pipe(
            switchMap((session) => {
                let jwttoken;
                const token = session.accessToken.jwtToken;
                const sessionId = localStorage.getItem('sessionId');
                if ((request.url.includes(url) || request.url.includes(urlCore)) && token && sessionId) {
                    jwttoken = request.clone({
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: 'Bearer ' + token,
                            sessionId: sessionId
                        },
                    });
                } else if (request.url.includes(urlAuth) && token) {
                    jwttoken = request.clone({
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: 'Bearer ' + token
                        },
                    });
                } else {
                    jwttoken = request.clone();
                }
                return next.handle(jwttoken).pipe(tap((e: HttpEvent<any>) => {
                    if (e instanceof HttpResponse) {}
                    return e;
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.router.navigate(['/sign-in']);
                        }
                    }
                }));
            })
        );

        /*return this.cognitoService.getAccessToken().pipe(
            switchMap((jwtToken) => {
                // clone the request to add the new header.
                const authReq = request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${jwtToken}`)
                });
                return next.handle(request).pipe(tap((e: HttpEvent<any>) => {
                    if (e instanceof HttpResponse) {}
                    return e;
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.router.navigate(['/sign-in']);
                        }
                    }
                }));
            })
        );*/

        /*this.cognitoService.getToken().then( (response) => {
            const token = response;
            if (token) {
                if (request.url.search(url) === -1) {
                    console.log(token);
                    request = request.clone({
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: `Bearer ${token}`,
                        },
                    });
                }
            }
        });*/
    }
}
