<div [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label>{{field.label}}</mat-label>
    <input currencyMask matTooltip="{{ field.short_description }}" matInput
           [formControlName]="field.columnname.toLowerCase()"
           [required]="field.required"
           [options]="currencyMaskConfig"
           autocomplete="off" />
    <!--<mat-error *ngIf="form.get(field.name).hasError('required')">
        &lt;!&ndash;{{ field.errorMessage.value }}&ndash;&gt;
    </mat-error>-->
  </mat-form-field>
</div>
