import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OptionCatalogGenericInterface } from '../../../../interfaces/option-catalog-generic.interface';

@Component({
    selector: 'app-atom-input-selectbox',
    templateUrl: './input-selectbox.component.html',
    styleUrls: ['./input-selectbox.component.sass']
})
export class InputSelectboxComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;

    options: OptionCatalogGenericInterface[] = [];

    constructor() {}

    ngOnInit(): void {
      if (this.field.datacontent !== null) {
        this.field.datacontent.forEach( item => {
          const op: OptionCatalogGenericInterface  = {
            recordId: item.ad_ref_list_id,
            description: item.name,
          };
          this.options.push(op);
        });
      }
    }

}
