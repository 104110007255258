<div [formGroup]="form">

  <div class="form-floating d-flex justify-content-between" style="border: none;">
    <input placeholder="{{field.label}}" class="form-control" 
      style="border: 1.5px solid #98CADC; border-radius: 8px 0 0 8px; color: var(--space-cadet); border-right-color: transparent;"
      [formControlName]="field.columnname.toLowerCase()" [required]="field.required" autocomplete="off" />
    <button class="btn" style="cursor: pointer; background-color: #98CADC; color: var(--space-cadet); border-radius: 8px; max-height: 30%; max-width: 100%" (click)="openDialog()">Search</button>
    <label style="color: var(--space-cadet);">{{field.label}}</label>
  </div>

  <div class="row" *ngIf="form.get(field.columnname.toLowerCase()).hasError('required') && form.get(field.columnname.toLowerCase()).touched">
    <p class="d-flex" style="color: red; margin-top: 5px;"> 
      <i class="fa fa-exclamation-triangle mt-1 ml-5" aria-hidden="true"></i>
      <span style="padding-left: 8px;"> {{field.name}} required </span> 
    </p>
  </div>

</div>
