import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ]
})
export class PaginationComponent implements OnChanges {

  @Input() firstPage: boolean
  @Input() firstPageLink?: string
  @Input() lastPage: boolean
  @Input() lastPageLink?: string
  @Input() totalPage: number
  @Input() currentPage: number
  @Input() recordByPage?: number
  @Input() numberOfElements: number
  @Input() totalElements: number

  @Output() PageSelected$ = new EventEmitter<number>()

  public listShowPages = []

  constructor() {
    this.recordByPage = 20
  }

  goLastPage() {
    this.notifycationPageSelected(this.totalPage - 1)
  }

  notifycationPageSelected(page: number) {
    this.PageSelected$.emit(page)
  }

  ngOnChanges(): void {
    this.listShowPages = _.range(this.totalPage || 1)

    if (this.currentPage < 6) {
      this.listShowPages = [ ...this.listShowPages.slice(0, 4) ]
    } else if (this.currentPage >= 6 && (this.totalPage - this.currentPage) >= 6) {
      this.listShowPages = [ ...this.listShowPages.slice(this.currentPage - 4, this.currentPage + 2) ]
    } else {
      this.listShowPages = [ ...this.listShowPages.slice(this.totalPage - 5, this.totalPage) ]
    }
  }
}
