import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {NgbDatepicker, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-atom-input-date',
    templateUrl: './input-date.component.html',
    // styleUrls: ['./input-text.component.sass']
})
export class InputDateComponent implements OnInit {

    @ViewChild(NgbDatepicker) datepicker: NgbDatepicker;
    minDate = {year: 1960, month: 1, day: 1};

    @Input() field: any = {};
    @Input() form: FormGroup;

    get isValid() {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }

    constructor() {
    }

    ngOnInit(): void {
    }

} 
