<div [formGroup]="form">
    <mat-form-field class="w-full">
        <mat-label>{{field.label}}</mat-label>
        <input matTooltip="{{ field.short_description }}" matInput [formControlName]="field.columnname.toLowerCase()" [required]="field.required" type="text" autocomplete="off"/>
        <mat-icon matSuffix >create</mat-icon>
        <!--<mat-error *ngIf="form.get(field.name).hasError('required')">
            &lt;!&ndash;{{ field.attributes.errorMessage.value }}&ndash;&gt;
        </mat-error>-->
    </mat-form-field>
</div>
