import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ResponseRolesInterface} from '../interfaces/ResponseRolesInterface';
import {ResponseMenuInterface} from '../interfaces/ResponseMenuInterface';
import {ResponseWindowsFormInterface} from '../interfaces/response/windows-form-response/response-windows-form.interface';
import {ResponseTabFormInterface} from '../interfaces/response/windows-form-response/response-tab-form.interface';
import {ResponseGeneric} from '../interfaces/ResponseGeneric';
import {FuseSplashScreenService} from '../../../@fuse/services/splash-screen';
import {GenericCatalogInterface} from '../../modules/credit-admin/interfaces/generic-catalog.interface';
import {CustomerResponse, EditCustomerResponse} from "../interfaces/customer/CustomerResponse";
import {ValidateAmountInterface} from "../interfaces/credits/validate-amount.interface";

@Injectable({
    providedIn: 'root'
})
export class ApiCoreService {

    private apiUrl = environment.apiAdminUrl;

    constructor(
        private http: HttpClient,
        private spinner: FuseSplashScreenService
    ) {
    }

    public listUser(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/user/list', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public createWebUser(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/user', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getReportFields(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/config/searchfields', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getWindow(id): Observable<ResponseWindowsFormInterface> {
        this.spinner.show();
        return this.http.get<ResponseWindowsFormInterface>(this.apiUrl + `/config/windows/${id}`).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getTab(id): Observable<ResponseTabFormInterface> {
        this.spinner.show();
        return this.http.get<ResponseTabFormInterface>(this.apiUrl + `/config/tabs/${id}`).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getRoles(): Observable<ResponseRolesInterface> {
        this.spinner.show();
        return this.http.get<ResponseRolesInterface>(this.apiUrl + '/session/roles').pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public configRoles(body): Observable<ResponseRolesInterface> {
        this.spinner.show();
        return this.http.post<ResponseRolesInterface>(this.apiUrl + '/session/roles', body,
            {headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')}).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getMenu(): Observable<ResponseMenuInterface> {
        this.spinner.show();
        return this.http.get<ResponseMenuInterface>(this.apiUrl + '/session/menu').pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getQuickTab(id): Observable<ResponseTabFormInterface> {
        this.spinner.show();
        return this.http.get<ResponseTabFormInterface>(this.apiUrl + `/config/quicktabs/${id}`).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getDataGrid(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/data/grid', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public insertCustomer(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/customer', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getRegionCatalog(body): Observable<GenericCatalogInterface> {
        this.spinner.show();
        return this.http.post<GenericCatalogInterface>(this.apiUrl + '/data/generic-catalog', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getGridByField(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>( this.apiUrl + '/data/gridByField', body ).pipe(finalize(() => {
          this.spinner.hide();
        }));
      }

    public getGenericCatalog(body): Observable<GenericCatalogInterface> {
        this.spinner.show();
        return this.http.post<GenericCatalogInterface>(this.apiUrl + '/data/generic-catalog', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getCustomerList(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/customer/list', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getToolbarConfig(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/config/toolbar', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getSearchField(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>( this.apiUrl + '/config/searchfields/', body).pipe(finalize(() => {
          this.spinner.hide();
        }));
      }

    public getRecordId(body): Observable<any> {
        this.spinner.show();
        return this.http.post<any>(this.apiUrl + '/data/record', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getOpSelect(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>( this.apiUrl + '/config/opselect', body).pipe(finalize(() => {
          this.spinner.hide();
        }));
      }

    public getInfoSession(): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.get<ResponseGeneric>(this.apiUrl + '/session/info').pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public deleteRecord(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/data/delete', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public updateRecord(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/data/update', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public insertRecord(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/data/insert', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public getCustomerById(bpartnerId): Observable<CustomerResponse> {
        this.spinner.show();
        return this.http.get<CustomerResponse>(this.apiUrl + `/customer/${bpartnerId}`)
            .pipe(
                finalize(() => this.spinner.hide()),
            );
    }

    public editCustomer(bpartnerId, body): Observable<EditCustomerResponse> {
        this.spinner.show();
        return this.http.post<EditCustomerResponse>(this.apiUrl + `/customer/${bpartnerId}`, body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public validAmount(body): Observable<ValidateAmountInterface> {
        this.spinner.show();
        return this.http.post<ValidateAmountInterface>(this.apiUrl + '/creditscore/validate/amount', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public setPassUser(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/user/setpass', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public createMobileUser(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/user/partner', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public resetPassMobileUser(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.post<ResponseGeneric>(this.apiUrl + '/user/resetpass', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }

    public updateMobileUser(body): Observable<ResponseGeneric> {
        this.spinner.show();
        return this.http.put<ResponseGeneric>(this.apiUrl + '/user/partner', body).pipe(finalize(() => {
            this.spinner.hide();
        }));
    }
}
