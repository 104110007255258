import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CurrencyMaskConfig} from 'ngx-currency';

@Component({
  selector: 'app-atom-input-amount',
  templateUrl: './input-amount.component.html',
  // styleUrls: ['./input-text.component.sass']
})
export class InputAmountComponent implements OnInit {

  @Input() field: any = {};
  @Input() form: FormGroup;

  currencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    allowZero: false,
    decimal: ".",
    precision: 2,
    prefix: "$ ",
    suffix: "",
    thousands: ",",
    nullable: true
  };

  get isValid(): boolean {
    return this.form.controls[this.field.name].valid;
  }

  get isDirty(): boolean {
    return this.form.controls[this.field.name].dirty;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
