<style>
  .striped-table tr:nth-child(odd) {
    background-color: #FFFFFF; 
    /* background-color: #f0c7cc; */
  }
  
  .striped-table tr:nth-child(even) {
    background-color: #FFFFFF; 
  }
  
  .new-row {
    background-color: #ddd !important;
  }
  
  #t_grid {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #t_grid td, #t_grid th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #t_grid tr:hover {
    background-color: #ddd;
  }
  
  #t_grid th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
  }
  </style>
  
    <div *ngIf="showData">
      
      <form [formGroup]="searchForm">
        <div class="row">
          <div class="col-6 d-flex align-items-center justify-content-center">
              <h5> Search {{info?.fieldNameSelected}} </h5>
          </div>
          <div class="col-6 d-flex align-items-end justify-content-end">
              <button type="button" class="btn" (click)="closeDialog()" style="background-color: var(--low-pink); border-radius: 50%; width: 40px; height: 40px;">
                  <i class="fa fa-times fa-lg rota" style="color: #951717" aria-hidden="true"></i>
              </button>
          </div>
      </div>
      <hr>
        <div class="row ml-5 mr-5" style="text-align: center; padding: 15px; margin-top: 20px;">
          <div class="col-4 text-center" *ngFor="let field of fieldsSearch">
            <app-component-field-builder [field]='field' [form]="searchForm"></app-component-field-builder>
          </div>
        </div>
        <div class="row ml-5 mr-5 d-flex">
          <div class="col">
              <button matTooltip="Search" matTooltipPosition="above" type="submit" class="btn ml-4 mr-5" style="background-color: #98CADC; width: 5%;">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn" style="background-color: var(--space-cadet); color: #FFF; width: auto;" (click)="addRecord()"> Add {{info?.fieldNameSelected}} </button>
          </div>
        </div>
      </form>
  
      <div class="row" style="margin-top: 20px; padding: 20px;">
  
        <div class="header d-flex justify-content-between">
          <h5 style="font-size: 15px; color: var(--space-cadet);">Record Selected: {{ recordIdSelected }}</h5>
        </div>
    
        <div class="body table-responsive">
          <table class="table striped-table" id="t_grid">
            <thead>
              <tr>
                <th *ngFor="let row of columns" style="min-width:150px; background-color: var(--space-cadet);">{{ row.value }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let line of data" [ngClass]="{'new-row': line.recordId === recordIdSelected}" (click)="recordSelected(line)">
                <td *ngFor="let item of line.data">
                  <span *ngIf="item.dataType !== 'Date' && item.dataType !== 'Amount'">{{ item.value }}</span>
                  <span *ngIf="item.dataType === 'Date'">{{ formatDate(item.value) }}</span>
                  <span *ngIf="item.dataType === 'Amount'" style="text-align: right;">{{ item.value | currency }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <app-pagination (PageSelected$)="onPageSelected($event)"
        [currentPage]="this.pageSelected" [firstPage]="this.firtsPage"
        [lastPage]="this.lastPage" [numberOfElements]="this.numberOfElements"
        [recordByPage]="10" [totalElements]="this.totalrecords"
        [totalPage]="this.totalpages"></app-pagination>
  
      </div>
    </div>


    <div *ngIf="showAdd">
      <add-form (backEmitter)="back()" [fieldIdSelected]="info?.fieldIdSelected"></add-form>
    </div>