<form [formGroup]="addForm">
  <div class="row">
    <div class="col-6 d-flex align-items-center justify-content-center">
        <h5> Add </h5>
    </div>
    <div class="col-6 d-flex align-items-end justify-content-end">
        <button type="button" class="btn" (click)="closeModal()" style="background-color: var(--low-pink); border-radius: 50%; width: 40px; height: 40px;">
            <i class="fa fa-times fa-lg rota" style="color: #951717" aria-hidden="true"></i>
        </button>
    </div>
  </div>
<hr>
  <div class="row mb-6" style="padding: 18px;" *ngFor="let fieldGroupItem of this.tab.fieldgroups">
    <div class="col-4 mt-3" *ngFor='let field of fieldGroupItem.fields'>
      <app-component-field-builder  [field]='field' [form]="addForm"></app-component-field-builder>
    </div>
  </div>
  <div class="row">
      <button type="button" class="btn" style="background-color: var(--space-cadet); color: #FFF; max-width: 5%;" (click)="saveRecord()"> Save </button>
  </div>
</form>
