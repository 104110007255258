import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-atom-input-text',
    templateUrl: './input-text.component.html',
    // styleUrls: ['./input-text.component.sass']
})
export class InputTextComponent implements OnInit {

    @Input() field: any = {};
    @Input() form: FormGroup;
    restriction: boolean = false;

    get isValid(): boolean {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty(): boolean {
        return this.form.controls[this.field.name].dirty;
    }

    constructor() {
    }

    ngOnInit(): void {
      if (this.field.columnname === "Phone") {
        this.restriction = true;
      }
    }

}
